import { defineStore } from 'pinia'

export const useOwnerChatStore = defineStore('ownerChatStore', {
  state: () => ({
    selected: null,
  }),
  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('owner_chats', {
        query: params,
      }))
    },

    async fetch(id: number) {
      return await $api(`/owner_chats/${id}`)
    },

    async setAdminReaded(id: number) {
      return await $api(`/owner_chats/admin/readed/${id}`)
    },

    async setOwnerReaded(id: number) {
      return await $api(`/owner_chats/owner/readed/${id}`)
    },

    async update(data: any) {
      return await $api(`/owner_chats/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async store(data: any, url: string) {
      return await $api(`${url}`, {
        method: 'POST',
        body: data,
      })
    },
  },
})
